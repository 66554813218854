<template>
	<div class="search">
		<div class="content-yellow-note">
			<span><b-icon icon="bell" class="icon"></b-icon> หากไม่พบรุ่นหรือขนาดสินค้าที่ต้องการจะขาย กรุณาติดต่อเจ้าหน้าที่ Yellowtire ที่ <a href="mailto:info@yellowtire.com" target="_blank">info@yellowtire.com</a> หรือ +66 (0) 2026 7407 ทางเราจะทำการตรวจสอบและเพิ่มสินค้าใหม่ในฐานข้อมูลภายใน 24 ชม.</span>
		</div>

		<div class="form">
			<div class="row">
				<div class="col col-6 col-md-4">
					<div class="input-field">
						<input type="text"
							v-model="form.sku"
							placeholder="รหัสสินค้า"
							maxlength="50"
							class="square" />
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field">
						<dropdown
							v-model="form.display"
							placeholder="สถานะการแสดงบนเว็บ"
							:classes="{ input: [ 'square', 'input-option' ] }"
							:options="option.display">
						</dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field">
						<dropdown
							v-model="form.status"
							placeholder="สถานะสินค้า"
							:classes="{ input: [ 'square', 'input-option' ] }"
							:options="option.status">
						</dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field">
						<dropdown
							v-model="form.brand"
							placeholder="ยี่ห้อยาง"
							:classes="{ input: [ 'square', 'input-option' ] }"
							:options="option.brand"
							@change="changeBrand($event)">
						</dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field">
						<dropdown
							v-model="form.model"
							placeholder="รุ่น"
							:classes="{ input: [ 'square', 'input-option' ] }"
							:options="option.model">
						</dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field">
						<dropdown
							v-model="form.width"
							placeholder="ความกว้างยาง"
							:classes="{ input: [ 'square', 'input-option' ] }"
							:options="option.width"
							@change="changeWidth($event)">
						</dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field">
						<dropdown
							v-model="form.ratio"
							placeholder="ซีรี่ย์"
							:classes="{ input: [ 'square', 'input-option' ] }"
							:options="option.ratio"
							@change="changeRatio(form.width, $event)">
						</dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field">
						<dropdown
							v-model="form.diameter"
							placeholder="ขอบล้อ"
							:classes="{ input: [ 'square', 'input-option' ] }"
							:options="option.diameter">
						</dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field">
                        <dropdown
                            v-model="form.year"
                            placeholder="ปีผลิต"
                            :classes="{
                                input: [ 'square' ]
                            }"
                            :options="option.year">
                        </dropdown>
					</div>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-field">
						<dropdown
							v-model="form.promotion"
							placeholder="โปรโมชั่น"
							:classes="{ input: [ 'square' ] }"
							:options="option.promotion">
						</dropdown>
					</div>
				</div>
			</div>

			<div class="mt-3 text-center">
				<button class="btn btn-main btn-search" @click="submitSearch()"><font-awesome-icon :icon="['fas', 'search']" class="icon" /> ค้นหา</button>
				<button class="btn btn-reset" @click="resetForm()">รีเซ็ต</button>
			</div>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import Constants from '@/variables/constants';
import MasterService from '@/services/masterService';

export default {
	data() {
		return {
			constants: Constants,
			form: this.initForm(),
			option: {
                brand: [],
                model: [],
                width: [],
                ratio: [],
                diameter: [],
                promotion: [],
				year: Helper.initStockYearOption(),
				display: [],
				status: []
            }
		}
	},
	props: {
		activeStatus: Number
	},
	mounted() {
		this.initOptionStatus();
		this.initTireBrand();
        this.initTireSize();
		this.initPromotionList();
	},
	methods: {
		initOptionStatus() {
			this.option.display = [];
			this.option.status = [];

			// Display
			this.option.display.push({ id: '1', name: 'เฉพาะที่แสดงบนเว็บ' });
			this.option.display.push({ id: '0', name: 'เฉพาะที่ไม่ได้แสดงบนเว็บ' });
			
			// Status
			const statusList = Helper.getStatusList();

			statusList.forEach((item) => {
				this.option.status.push({
					id: item.statusCode,
					name: item.statusText
				});
			});
		},
		initForm() {
			return {
				sku: '',
				display: '',
				status: Constants.SELLER_STOCK_STATUS.ALL,
				brand: '',
				model: '',
                width: '',
                ratio: '',
                diameter: '',
				promotion: '',
				year: ''
			};
		},
		async initTireBrand() {
            const result = await MasterService.getProductBrand();

			this.option.brand = Helper.setupOption(result.data);
        },
        async initTireSize() {
            const result = await MasterService.getTireWidth();

			this.option.width = Helper.setupOption(result.data);
        },
		async initPromotionList() {
			const result = await MasterService.masterGetPromotionList(0);

            this.option.promotion = [];

            result.data.forEach((item) => {
                this.option.promotion.push({
                    id: item.id,
                    name: item.name
                });
            });
		},
		async changeWidth(width) {
            if (width) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getTireRatio(width);
                loader.hide();

                this.option.diameter = [];

				this.option.ratio = Helper.setupOption(result.data);
            } else {
                this.option.ratio = [];
                this.option.diameter = [];
            }

            this.form.ratio = '';
            this.form.diameter = '';
        },
        async changeRatio(width, ratio) {
            if (width && ratio) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getTireDiameter(width, ratio);
                loader.hide();

				this.option.diameter = Helper.setupOption(result.data);
            } else {
                this.option.diameter = [];
            }

            this.form.diameter = '';
        },
        async changeBrand(brand) {
            if (brand) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getProductModel(brand);
                loader.hide();

				this.option.model = Helper.setupOption(result.data);
            } else {
                this.option.model = [];
            }

            this.form.model = '';
        },
		submitSearch() {
			this.$emit('submitSearch', this.form);
		},
		resetForm() {
			this.form = this.initForm();
			this.submitSearch();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.search {
	.form {
		.row {
			margin-left: -5px;
			margin-right: -5px;

			.col {
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		.input-field {
			margin-top: 8px;

			@media only screen and (max-width: $screenExtraSmall) {
				margin-top: 5px;
			}

			input {
				font-size: 14px;
			}
		}

		.btn {
			padding: 5px;
			width: 120px;

			.icon {
				font-size: 12px;
				margin-right: 5px;
			}

			&.btn-reset {
				background: #ccc;
			}
		}
	}
}

.sort-option {
	background: #7e7a7a;
	width: 150px;

	.input-option {
		width: 100%;
		height: 30px;
		background: none;
		border: none;
		color: #fff;
		text-align: center;

		@media only screen and (max-width: $screenMedium) {
			font-size: 14px;
		}

		option {
			color: #000;
		}
	}
}

::v-deep .form {
	.form-option {
		.input-option {
			font-size: 14px;

			&[readonly] {
				background: #fff;
			}
		}
	}
}
</style>