<template>
	<section v-if="form">
		<button @click="backToSearch()" class="btn btn-back">
			<font-awesome-icon :icon="['fas', 'chevron-left']" class="icon" /> กลับไปหน้าค้นหา
		</button>

		<div class="form mt-2">
			<h2 v-if="mode === constants.SELLER_STOCK_FORM_MODE.NEW">เพิ่มสินค้าใหม่</h2>
			<h2 v-if="mode === constants.SELLER_STOCK_FORM_MODE.EDIT">แก้ไขข้อมูล</h2>

			<div class="row">
				<div class="col-sm-12 col-md-9">
					<div class="row field">
						<div class="col col-6">
							<div class="input-field mb-2">
								<label>ยี่ห้อยาง</label>
								<dropdown
									v-model="form.brand"
									placeholder="ยี่ห้อยาง"
									:disabled="mode === constants.SELLER_STOCK_FORM_MODE.EDIT"
									:classes="{
										input: [
											'square',
											'input-option',
											(mode === constants.SELLER_STOCK_FORM_MODE.EDIT ? 'readonly' : ''),
											(error.brand ? 'invalid' : '')
										]
									}"
									:options="option.brand"
									@change="changeBrand($event)">
								</dropdown>
							</div>
						</div>
						<div class="col col-6">
							<div class="input-field mb-2">
								<label>รุ่น</label>
								<dropdown
									v-model="form.model"
									placeholder="รุ่น"
									:disabled="mode === constants.SELLER_STOCK_FORM_MODE.EDIT"
									:classes="{
										input: [
											'square',
											'input-option',
											(mode === constants.SELLER_STOCK_FORM_MODE.EDIT ? 'readonly' : ''),
											(error.brand ? 'invalid' : '')
										]
									}"
									:options="option.model"
									@change="changeModel(form.brand, $event)">
								</dropdown>
								<div class="icon-loader">
									<loader-spinner
										v-if="isLoadModel"
										sizeWidth="20px"
										sizeHeight="20px">
									</loader-spinner>
								</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>ความกว้างยาง</label>
								<dropdown
									v-model="form.width"
									placeholder="ความกว้างยาง"
									:disabled="mode === constants.SELLER_STOCK_FORM_MODE.EDIT"
									:classes="{
										input: [
											'square',
											'input-option',
											(mode === constants.SELLER_STOCK_FORM_MODE.EDIT ? 'readonly' : ''),
											(error.brand ? 'invalid' : '')
										]
									}"
									:options="option.width"
									@change="changeWidth(form.brand, form.model, $event)">
								</dropdown>
								<div class="icon-loader">
									<loader-spinner
										v-if="isLoadWidth"
										sizeWidth="20px"
										sizeHeight="20px">
									</loader-spinner>
								</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>ซีรี่ย์</label>
								<dropdown
									v-model="form.ratio"
									placeholder="ซีรี่ย์"
									:disabled="mode === constants.SELLER_STOCK_FORM_MODE.EDIT"
									:classes="{
										input: [
											'square',
											'input-option',
											(mode === constants.SELLER_STOCK_FORM_MODE.EDIT ? 'readonly' : ''),
											(error.brand ? 'invalid' : '')
										]
									}"
									:options="option.ratio"
									@change="changeRatio(form.brand, form.model, form.width, $event)">
								</dropdown>
								<div class="icon-loader">
									<loader-spinner
										v-if="isLoadRatio"
										sizeWidth="20px"
										sizeHeight="20px">
									</loader-spinner>
								</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>ขอบล้อ</label>
								<dropdown
									v-model="form.diameter"
									placeholder="ขอบล้อ"
									:disabled="mode === constants.SELLER_STOCK_FORM_MODE.EDIT"
									:classes="{
										input: [
											'square',
											'input-option',
											(mode === constants.SELLER_STOCK_FORM_MODE.EDIT ? 'readonly' : ''),
											(error.brand ? 'invalid' : '')
										]
									}"
									:options="option.diameter"
									@change="changeDiameter($event)">
								</dropdown>
								<div class="icon-loader">
									<loader-spinner
										v-if="isLoadDiameter"
										sizeWidth="20px"
										sizeHeight="20px">
									</loader-spinner>
								</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>รหัสสินค้า</label>
								<input type="text"
									v-model="form.sku"
									placeholder="รหัสสินค้า"
									readonly
									class="square readonly"
									:class="{ 'invalid': error.sku }" />
								<div class="icon-loader">
									<loader-spinner
										v-if="isLoadSku"
										sizeWidth="20px"
										sizeHeight="20px">
									</loader-spinner>
								</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>กลุ่ม</label>
								<input type="text"
									v-model="form.group"
									placeholder="กลุ่ม"
									readonly
									class="square readonly" />
								<div class="icon-loader">
									<loader-spinner
										v-if="isLoadSku"
										sizeWidth="20px"
										sizeHeight="20px">
									</loader-spinner>
								</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>ปีผลิต</label>
								<dropdown
									v-model="form.year"
									placeholder="ปีผลิต"
									:disabled="mode === constants.SELLER_STOCK_FORM_MODE.EDIT"
									:classes="{
										input: [
											'square',
											'input-option',
											(mode === constants.SELLER_STOCK_FORM_MODE.EDIT ? 'readonly' : ''),
											(error.year ? 'invalid' : '')
										]
									}"
									:options="option.year">
								</dropdown>
							</div>
						</div>
					</div>
					<div class="content-yellow-note note mt-2 mb-2" v-if="isLockPrice">
						<span><b-icon icon="sticky" class="icon"></b-icon> ราคาสินค้านี้ถูกกำหนดโดยราคาส่วนกลาง</span>
					</div>
					<div class="row field">
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>ราคาขาย (ติดตั้ง)</label>
								<div class="input-unit">
									<input type="tel"
										@keypress="$AppUtility.inputOnlyNumber($event)"
										v-model="form.price"
										placeholder="ราคาต่อเส้น"
										class="square"
										:class="{ 'readonly': isLockPrice }"
										:readonly="isLockPrice"
										maxlength="8" />
									<span>บาท</span>
								</div>
								<div class="remark">** หากไม่ระบุ = ไม่รับติดตั้ง</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>ราคาขาย (จัดส่ง)</label>
								<div class="input-unit">
									<input type="tel"
										@keypress="$AppUtility.inputOnlyNumber($event)"
										v-model="form.priceDelivery"
										placeholder="ราคาต่อเส้น"
										class="square"
										:class="{ 'readonly': isLockPrice }"
										:readonly="isLockPrice"
										maxlength="8" />
									<span>บาท</span>
								</div>
								<div class="remark">** หากไม่ระบุ = ไม่รับจัดส่ง</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>จำนวนสต๊อค</label>
								<div class="input-unit">
									<input type="tel"
										@keypress="$AppUtility.inputOnlyNumber($event)"
										v-model="form.stock"
										placeholder="จำนวนสต๊อค"
										class="square"
										maxlength="6"
										:class="{ 'invalid': error.stock }" />
									<span>เส้น</span>
								</div>
							</div>
						</div>
					</div>
					<div class="row field">
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>ค่าจัดส่งพื้นที่โซน 1</label>
								<div class="input-unit">
									<input type="tel"
										@keypress="$AppUtility.inputOnlyNumber($event)"
										v-model="form.deliveryFee1"
										placeholder="ค่าจัดส่งพื้นที่โซน 1"
										class="square"
										maxlength="8" />
									<span>บาท</span>
								</div>
								<div class="remark">** หากไม่ระบุ = ไม่จัดส่งโซน 1</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>ค่าจัดส่งพื้นที่โซน 2</label>
								<div class="input-unit">
									<input type="tel"
										@keypress="$AppUtility.inputOnlyNumber($event)"
										v-model="form.deliveryFee2"
										placeholder="ค่าจัดส่งพื้นที่โซน 2"
										class="square"
										maxlength="8" />
									<span>บาท</span>
								</div>
								<div class="remark">** หากไม่ระบุ = ไม่จัดส่งโซน 2</div>
							</div>
						</div>
					</div>
					<div class="row field">
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>ค่าจัดส่งพื้นที่โซน 3</label>
								<div class="input-unit">
									<input type="tel"
										@keypress="$AppUtility.inputOnlyNumber($event)"
										v-model="form.deliveryFee3"
										placeholder="ค่าจัดส่งพื้นที่โซน 3"
										class="square"
										maxlength="8" />
									<span>บาท</span>
								</div>
								<div class="remark">** หากไม่ระบุ = ไม่จัดส่งโซน 3</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>ค่าจัดส่งพื้นที่โซน 4</label>
								<div class="input-unit">
									<input type="tel"
										@keypress="$AppUtility.inputOnlyNumber($event)"
										v-model="form.deliveryFee4"
										placeholder="ค่าจัดส่งพื้นที่โซน 4"
										class="square"
										maxlength="8" />
									<span>บาท</span>
								</div>
								<div class="remark">** หากไม่ระบุ = ไม่จัดส่งโซน 4</div>
							</div>
						</div>
					</div>
					<div class="row field">
						<div class="col col-12 col-sm-4">
							<div class="input-field mb-2">
								<label>SKU สำหรับร้านค้า</label>
								<input type="text"
									v-model="form.sellerSku"
									placeholder="เว้นว่างได้"
									class="square"
									maxlength="50" />
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>สถานะ</label>
								<dropdown
									v-model="form.status"
									placeholder="สถานะ"
									:classes="{
										input: [
											'square',
											'input-option',
											(error.status ? 'invalid' : '')
										]
									}"
									:options="option.status">
								</dropdown>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<label>&nbsp;</label>
								<div class="checkbox">
									<checkbox 
										v-model="form.isShowOnWeb">
										แสดงผลบนเว็บ
									</checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="row field">
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<div class="checkbox">
									<checkbox 
										v-model="form.isAllowMobileBanking">
										ชำระเงินผ่านโมบายแบงค์กิ้ง
									</checkbox>
								</div>
							</div>
						</div>
						<div class="col col-6 col-sm-4">
							<div class="input-field mb-2">
								<div class="checkbox">
									<checkbox 
										v-model="form.isAllowCreditCard">
										ชำระเงินผ่านบัตรเครดิต
									</checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-3">
					<div class="picture">
						<div class="wrapper">
							<label v-if="!picture">รูปสินค้า</label>
							<img :src="picture" v-if="picture" @error="helper.setNoImg($event, 'product')" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-3 text-center">
			<button class="btn btn-main btn-submit square" @click="saveStock()">บันทึกสินค้า</button>
		</div>
	</section>
</template>

<script>
import Constants from '@/variables/constants';
import Helper from '@/services/helper';
import MasterService from '@/services/masterService';
import SellerService from '@/services/sellerService';
import OptionStockStatus from '@/services/staticOption/stockStatus';
import LoaderSpinner from '@/components/utility/LoaderSpinner';

export default {
	components: { LoaderSpinner },
	data() {
		return {
			helper: Helper,
			constants: Constants,
			form: null,
			error: this.initError(),
			option: {
                brand: [],
                model: [],
                width: [],
                ratio: [],
                diameter: [],
				year: Helper.initStockYearOption(),
				status: OptionStockStatus.option
            },
			picture: '',
			isLoadModel: false,
			isLoadWidth: false,
			isLoadRatio: false,
			isLoadDiameter: false,
			isLoadSku: false,
			isLockPrice: false,
		}
	},
	props: {
		mode: {
            type: String,
            default: () => Constants.SELLER_STOCK_FORM_MODE.NEW
        },
		load: {
			type: Object,
			default: () => null
		}
	},
	async mounted() {
		await this.initTireBrand();
		
		this.form = this.initForm();
	},
	methods: {
		backToSearch() {
			this.$emit('closeStockForm');
		},
		initForm() {
			if (this.load) {
				this.isLockPrice = this.load.isLockPrice;
			}

			return {
				id: this.load ? this.load.id : '',
				brand: this.load ? this.load.brand : '',
				model: this.load ? this.load.model : '',
                width: this.load ? this.load.width : '',
                ratio: this.load ? this.load.ratio : '',
                diameter: this.load ? this.load.diameter : '',
				sku: this.load ? this.load.sku : '',
				group: this.load ? this.load.group : '',
				year: this.load ? this.load.year : '',
				price: this.load ? this.load.price : '',
				priceDelivery: this.load ? this.load.priceDelivery : '',
				deliveryFee1: this.load ? this.load.deliveryFee1 : '',
				deliveryFee2: this.load ? this.load.deliveryFee2 : '',
				deliveryFee3: this.load ? this.load.deliveryFee3 : '',
				deliveryFee4: this.load ? this.load.deliveryFee4 : '',
				stock: this.load ? this.load.stock : '',
				isAllowMobileBanking: this.load ? this.load.isAllowMobileBanking : true,
				isAllowCreditCard: this.load ? this.load.isAllowCreditCard : true,
				isAllowPrepaid: this.load ? this.load.isAllowPrepaid : false,
				isShowOnWeb: this.load ? this.load.isShowOnWeb : true,
				status: this.load ? this.load.status : '',
				sellerSku: this.load ? this.load.sellerSku : ''
			};
		},
		initError() {
			return {
				brand: false,
				model: false,
				width: false,
				ratio: false,
				diameter: false,
				year: false,
				stock: false,
				status: false
			}
		},
		async initTireBrand() {
            const result = await MasterService.getProductBrand();

			this.option.brand = Helper.setupOption(result.data);
        },
        async changeBrand(brand) {
            if (brand) {
				this.isLoadModel = true;
                const result = await MasterService.getProductModel(brand);
				this.isLoadModel = false;

				if (result.data.length > 0) {
					this.option.model = Helper.setupOption(result.data);
				} else {
					this.alertMasterNotExist();
				}
            } else {
                this.option.model = [];
				this.option.width = [];
				this.option.ratio = [];
                this.option.diameter = [];
            }

			this.form.model = this.option.model.find(x => x.id === this.form.model) ? this.form.model : '';

			if (!this.load) {
				this.generateSKU();
			}
        },
		async changeModel(brand, model) {
			if (brand && model) {
				this.isLoadWidth = true;
                const product = await MasterService.getProductPicture(brand, model);
				const option = await MasterService.getTireWidthByModel(brand, model);
				this.isLoadWidth = false;

				this.picture = product.data.picture;

				if (option.data.length > 0) {
					this.option.width = Helper.setupOption(option.data);
				} else {
					this.alertMasterNotExist();
				}
            } else {
                this.picture = '';
				this.option.width = [];
				this.option.ratio = [];
                this.option.diameter = [];
            }

			this.form.width = this.option.width.find(x => x.id.toString() === this.form.width.toString()) ? this.form.width : '';
			
			if (!this.load) {
				this.generateSKU();
			}
		},
		async changeWidth(brand, model, width) {
            if (brand && model && width) {
				this.isLoadRatio = true;
                const result = await MasterService.getTireRatioByModel(brand, model, width);
				this.isLoadRatio = false;

				if (result.data.length > 0) {
					this.option.ratio = Helper.setupOption(result.data);
				} else {
					this.alertMasterNotExist();
				}
            } else {
                this.option.ratio = [];
                this.option.diameter = [];
            }

			this.form.ratio = this.option.ratio.find(x => x.id.toString() === this.form.ratio.toString()) ? this.form.ratio : '';
			
			if (!this.load) {
				this.generateSKU();
			}
        },
        async changeRatio(brand, model, width, ratio) {
            if (brand && model && width && ratio) {
				this.isLoadDiameter = true;
                const result = await MasterService.getTireDiameterByModel(brand, model, width, ratio);
				this.isLoadDiameter = false;
				
				if (result.data.length > 0) {
					this.option.diameter = Helper.setupOption(result.data);
				} else {
					this.alertMasterNotExist();
				}
            } else {
                this.option.diameter = [];
            }

			this.form.diameter = this.option.diameter.find(x => x.id.toString() === this.form.diameter.toString()) ? this.form.diameter : '';
			
			if (!this.load) {
				this.generateSKU();
			}
        },
		changeDiameter(value) {
			if (value) {
				if (!this.load) {
					this.generateSKU();
				}
			}
		},
		alertMasterNotExist() {
			this.$swal(Helper.warningAlert('ไม่พบรุ่นหรือขนาดที่กำลังค้นหา', '', 'ไม่พบรุ่นหรือขนาดที่ค้นหา กรุณาติดต่อเจ้าหน้าที่ Yellowtire ที่ <a href="mailto:info@yellowtire.com" target="_blank">info@yellowtire.com</a> หรือ +66 (0) 2026 7407'));
		},
		async generateSKU() {
			if (!this.isLoadSku && this.form.brand && this.form.model && this.form.width && this.form.ratio && this.form.diameter) {
				this.isLoadSku = true;
                const result = await MasterService.getProductSku(this.form.brand, this.form.model, this.form.width, this.form.ratio, this.form.diameter);
				this.isLoadSku = false;

				if (result.data) {
					this.form.sku = result.data.sku;
					this.form.group = result.data.group;
					this.isLockPrice = result.data.isLockPrice;

					if (result.data.defaultPrice) {
						this.form.price = result.data.defaultPrice;
						this.form.priceDelivery = result.data.defaultPrice;
					}
				} else {
					this.alertMasterNotExist();
				}
			} else {
				this.form.sku = '';
				this.form.group = '';
				this.isLockPrice = false;
			}
		},
		async saveStock() {
			if (this.validate()) {
                let loader = this.$modalLoader.render();
				const result = await SellerService.saveStock(this.form);
				loader.hide();

				if (result.data?.success) {
					this.$emit('saveStockCompleted', this.mode);
				} else {
					this.$swal(Helper.warningAlert('ยังไม่สามารถบันทึกข้อมูลได้', result.data?.message));
				}
			}
        },
        validate() {
            this.error = this.initError();

            let isValid = true;
            let isBlank = false;
			let isNotSelectCountry = false;

			if (this.isLoadSku) {
				this.$swal(Helper.warningAlert('อยู่ระหว่างโหลด SKU', 'โปรดรอโหลดข้อมูล SKU ซักครู่'));

				return false;
			}

            // Check Blank
            for (const property in this.error) {
                if (!this.form[property]) {
                    this.error[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถบันทึกข้อมูลได้', 'โปรดกรอกข้อมูลในกล่องสีแดงให้ครบถ้วน'));
            } else if (isNotSelectCountry) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถบันทึกข้อมูลได้', 'โปรดเลือกประเทศผลิตยาง'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.btn-back {
	margin-bottom: 20px;
	background: none;
	border: none;

	.icon {
		color: #555;
		margin-right: 5px;
	}
}

.form {
	background: #edeae6;
	padding: 15px;

	h2 {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.field {
		margin-left: -5px;
		margin-right: -5px;

		.col {
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	.note {
		background: #efc35d;

		span {
			color: #512610;
		}
	}

	.input-field {
		position: relative;

		label {
			font-size: 13px;
			margin-bottom: 5px;
		}

		input, select {
			font-size: 14px;
			height: 37px;

			&.readonly {
				background: #ccc;
			}
		}

		.icon-loader {
			position: absolute;
			z-index: 1;
			bottom: 6px;
			right: 27px;
		}

		.remark {
			font-size: 11px;
			margin-top: 5px;
			color: #cc0000;
		}
	}
}

.checkbox {
	font-size: 14px;

	::v-deep .checkbox-control {
		.checkbox-button,
		.checkbox-label {
			margin-top: 0;
		}
	}
}

.picture {
	background: #bbb;
	border: 1px solid #999;
	padding-top: 100%;
	position: relative;
    max-width: 350px;
    margin: 28px auto 0;
	overflow: hidden;

	@media only screen and (max-width: $screenSmall) {
		padding-top: 60%;
	}

	.wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	label {
		font-size: 14px;
		color: #555;
	}

	img {
		width: 100%;
	}
}

.btn-submit {
	padding: 10px;
	width: 100%;
}

::v-deep .form-option {
    .input-option {
		font-size: 14px;

        &[readonly] {
			background: #fff;
		}

		&.readonly {
			background: #ccc;
		}
    }
}
</style>