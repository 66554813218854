const option = [
    { id: '1', name: 'ขาย-มีสต๊อก'},
    { id: '3', name: 'ขาย-สั่งจากโรงงาน'},
    { id: '2', name: 'ขาย-สั่งจากร้านอื่น'},
    { id: '4', name: 'ยกเลิกการขาย'},
];

const method = {
    getValueByKey(id) {
        return option.find(x => x.id === id).name;
    }
}

export default {
    option: option,
    service: method
};