<template>
	<div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
		<table>
			<thead>
				<tr>
					<th></th>
					<th class="sortable"
						:class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.ID }"
						@click="sortStock(constants.SORT_STOCK_COLUMN.ID)">
						รหัส/กลุ่ม
						<b-icon icon="chevron-down" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.ID">
						</b-icon>
						<b-icon icon="chevron-up" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.ID">
						</b-icon>
					</th>
					<th class="sortable"
						:class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.BRAND }"
						@click="sortStock(constants.SORT_STOCK_COLUMN.BRAND)">
						ยี่ห้อ/รุ่น
						<b-icon icon="chevron-down" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.BRAND">
						</b-icon>
						<b-icon icon="chevron-up" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.BRAND">
						</b-icon>
					</th>
					<th class="sortable"
						:class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.SIZE }"
						@click="sortStock(constants.SORT_STOCK_COLUMN.SIZE)">
						ขนาด
						<b-icon icon="chevron-down" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.SIZE">
						</b-icon>
						<b-icon icon="chevron-up" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.SIZE">
						</b-icon>
					</th>
					<th class="sortable text-center"
						:class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.YEAR }"
						style="width: 50px;"
						@click="sortStock(constants.SORT_STOCK_COLUMN.YEAR)">
						ปีผลิต
						<b-icon icon="chevron-down" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.YEAR">
						</b-icon>
						<b-icon icon="chevron-up" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.YEAR">
						</b-icon>
					</th>
					<th class="sortable text-end"
						:class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.QUANTITY }"
						@click="sortStock(constants.SORT_STOCK_COLUMN.QUANTITY)">
						จำนวน
						<b-icon icon="chevron-down" 
						class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.QUANTITY">
						</b-icon>
						<b-icon icon="chevron-up" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.QUANTITY">
						</b-icon>
					</th>
					<th class="sortable text-end"
						:class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE }"
						@click="sortStock(constants.SORT_STOCK_COLUMN.PRICE)">
						ราคาติดตั้ง
						<b-icon icon="chevron-down" 
						class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE">
						</b-icon>
						<b-icon icon="chevron-up" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE">
						</b-icon>
					</th>
					<th class="sortable text-end"
						:class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE_DELIVERY }"
						@click="sortStock(constants.SORT_STOCK_COLUMN.PRICE_DELIVERY)">
						ราคาจัดส่ง
						<b-icon icon="chevron-down" 
						class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE_DELIVERY">
						</b-icon>
						<b-icon icon="chevron-up" 
							class="icon"
							v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
								&& currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE_DELIVERY">
						</b-icon>
					</th>
					<th class="text-center">สถานะ</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in resultList" :key="index">
					<td class="text-center">
						<button @click="editStock(item)" class="btn btn-sm btn-edit">แก้ไข</button>
						<br />
						<button @click="deleteStock(item)" class="btn btn-sm btn-delete mt-2">ลบ</button>
					</td>
					<td>
						{{ item.sku }}
						<br />
						โมบายแบงค์กิ้ง: <strong class="text-success" v-if="item.isAllowMobileBanking">เปิด</strong> <strong class="text-danger" v-else>ปิด</strong> 
						<br />
						บัตรเครดิต: <strong class="text-success" v-if="item.isAllowCreditCard">เปิด</strong> <strong class="text-danger" v-else>ปิด</strong>
					</td>
					<td>{{ item.brand }} {{ item.model }}</td>
					<td>{{ item.size }}</td>
					<td class="text-center">{{ item.year }}</td>
					<td class="text-end">{{ item.stock }}</td>
					<td class="text-end">{{ item.price }}</td>
					<td class="text-end">{{ item.priceDelivery }}</td>
					<td class="text-center">
						<div class="operation">
							<select
								class="input-option"
								v-model="item.status"
								@change="changeStatus($event, item)">
								<option v-for="(item, index) in option.status" :key="index" :value="item.id">{{ item.name }}</option>
							</select>
							<div class="checkbox mt-2">
								<checkbox 
									v-model="item.isShowOnWeb"
									@afterTrigger="changeDisplay($event, item)">
									แสดงผลบนเว็บ
								</checkbox>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import Constants from '@/variables/constants';
import Helper from '@/services/helper';
import MixinDragScroll from '@/mixins/dragScroll';
import OptionStockStatus from '@/services/staticOption/stockStatus';
import SellerService from '@/services/sellerService';

export default {
	mixins: [ MixinDragScroll ],
	data() {
		return {
			constants: Constants,
			currentSortDirection: Constants.SORT_STOCK_DIRECTION.DEFAULT,
			currentSortColumn: Constants.SORT_STOCK_COLUMN.DEFAULT,
			option: {
				status: OptionStockStatus.option
            },
		}
	},
	props: {
        resultList: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		editStock(item) {
			this.$emit('editStock', { id: item.id });
		},
		deleteStock(item) {
			this.$swal(
                Helper.confirmAlert('ยืนยันการลบสินค้า', 'โปรดยืนยันว่าท่านต้องการลบสินค้า "' + [ item.brand, item.model, item.size ].join(' ') + '" ออกจากรายการสต๊อค')
            ).then(async (confirm) => {
                if (confirm.isConfirmed) {
                    this.$emit('deleteStock', { id: item.id });
                }
            });
		},
		sortStock(column) {
			if (this.currentSortColumn === column) {
				switch (this.currentSortDirection) {
					case Constants.SORT_STOCK_DIRECTION.ASC:
					this.currentSortDirection = Constants.SORT_STOCK_DIRECTION.DESC;
					break;

					case Constants.SORT_STOCK_DIRECTION.DESC:
					this.currentSortDirection = Constants.SORT_STOCK_DIRECTION.ASC;
					break;
				}
			} else {
				this.currentSortDirection = Constants.SORT_STOCK_DIRECTION.ASC;
			}

			this.currentSortColumn = column;
			this.$emit('sortStock', { direction: this.currentSortDirection, column: this.currentSortColumn });
		},
		async changeStatus(e, item) {
			let loader = this.$modalLoader.render();
			const result = await SellerService.updateStockStatus(item.id, e.target.value);
			loader.hide();

			if (!result.data?.success) {
				this.$swal(Helper.warningAlert('ไม่สามารถบันทึกข้อมูลได้', result.data?.message));
			}
		},
		async changeDisplay(status, item) {
			let loader = this.$modalLoader.render();
			const result = await SellerService.updateStockDisplay(item.id, status);
			loader.hide();

			if (!result.data?.success) {
				this.$swal(Helper.warningAlert('ไม่สามารถบันทึกข้อมูลได้', result.data?.message));
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

table {
    width: 100%;
    font-size: 13px;
	border: 1px solid #ddd;

    th, td {
        padding: 10px;
    }

    thead {
        th {
			background: #eee;
            color: #000;
			font-weight: normal;
			border-left: 1px solid #ddd;
			border-right: 1px solid #ddd;
			width: 63px;
			font-size: 12px;
			line-height: 15px;

			&.long-title {
				width: 200px;
			}

			&.sortable {
				cursor: pointer;
				min-width: 70px;

				&:hover {
					text-decoration: underline;
					opacity: 0.7;
				}

				.icon {
					margin-left: 5px;
					color: #666;
				}
			}
        }
    }

    tbody {
        td {
			color: #555;
            border-bottom: 1px solid #ddd;
			border-left: 1px solid #ddd;
			border-right: 1px solid #ddd;
			vertical-align: middle;
        }
    }

	.btn-edit {
		width: 46px;
		font-size: 11px;
		padding: 2px 10px;
		color: #fff;
		background: #56bdbd;
	}

	.btn-delete {
		width: 46px;
		font-size: 11px;
		color: #cc0000;
		background: none;
		border: 1px solid #cc0000;
	}

	.icon {
		font-size: 15px;
		color: #000;
	}

	span {
		display: block;
		font-size: 11px;
		white-space: nowrap;
	}

	.operation {
		display: inline-block;
	}

	.input-option {
		font-size: 12px;
		text-indent: 10px;
		width: 145px;
		height: 28px;
	}
}

::v-deep .checkbox-control {
    .checkbox-label {
        margin-left: 6px;
		text-align: left;
    }
}
</style>