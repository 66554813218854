<template>
	<div>
		<h2>รายการสินค้าในร้าน</h2>

		<div v-if="!isOpenStockForm">
			<seller-search-product
				:activeStatus="searchFilter.status"
				@submitSearch="submitSearch($event)">
			</seller-search-product>
		
			<template v-if="isResultReady">
				<div class="table-action mt-4 mb-3">
					<h3>จำนวนสินค้า ({{ totalResult }}) สต๊อคทั้งหมด ({{ totalStock }})</h3>
					<div class="operation">
						<button class="btn btn-action btn-danger square" @click="openStockForm(null)">เพิ่มสินค้าใหม่</button>
					</div>
				</div>

				<div class="remark mb-2">
					** ถ้าหากจำนวนสต๊อคเหลือต่ำกว่า 4 เส้นจะไม่แสดงสินค้าเพื่อขายบนหน้าเว็บไซต์
				</div>

				<seller-table-stock
					:resultList="resultList"
					@editStock="openStockForm($event)"
					@deleteStock="deleteStock($event)"
					@sortStock="sortStock($event)">
				</seller-table-stock>
				
				<paging
					@page="changePage($event)"
					:activePage="activePage"
					:totalPage="totalPage"
					class="mt-4">
				</paging>
			</template>
		</div>

		<div v-if="isOpenStockForm">
			<seller-stock-form
				:mode="stockMode"
				:load="selectedStock"
				@closeStockForm="closeStockForm()"
				@saveStockCompleted="saveStockCompleted($event)"
			></seller-stock-form>
		</div>
	</div>
</template>

<script>
import Constants from '@/variables/constants';
import SellerSearchProduct from '@/components/seller/stocks/SearchForm';
import SellerStockForm from '@/components/seller/stocks/StockForm';
import SellerTableStock from '@/components/seller/stocks/TableStock';
import RouterPath from '@/router/path';
import SellerService from '@/services/sellerService';

export default {
	components: {
		SellerSearchProduct,
		SellerStockForm,
		SellerTableStock
	},
	data() {
		return {
			isOpenStockForm: false,
			stockMode: Constants.SELLER_STOCK_FORM_MODE.NEW,
			selectedStock: null,
			isResultReady: false,
			resultList: [],
			totalResult: 0,
			totalStock: 0,
			activePage: 1,
			totalPage: 0,
			searchFilter: this.initSearchFilter()
		}
	},
	mounted() {
		this.getStock();
	},
	methods: {
		async openStockForm(data) {
			if (data) {
				let loader = this.$modalLoader.render();
				const result = await SellerService.getStockDetail(data.id);
				loader.hide();

				this.selectedStock = result.data;
				this.stockMode = Constants.SELLER_STOCK_FORM_MODE.EDIT;
			} else {
				this.selectedStock = null;
				this.stockMode = Constants.SELLER_STOCK_FORM_MODE.NEW;
			}

			this.isOpenStockForm = true;
		},
		closeStockForm() {
			this.isOpenStockForm = false;
		},
		async deleteStock(data) {
			let loader = this.$modalLoader.render();
			await SellerService.deleteStock(data.id);
			loader.hide();

			this.getStock();
		},
		saveStockCompleted(mode) {
			if (mode === Constants.SELLER_STOCK_FORM_MODE.NEW) {
				this.searchFilter.status = Constants.SELLER_STOCK_STATUS.LATEST;
				this.searchFilter = this.initSearchFilter();
			}

			this.getStock();
			this.closeStockForm();
		},
		initSearchFilter() {
			return {
				param: {
					sku: '',
					display: '',
					status: Constants.SELLER_STOCK_STATUS.ALL,
					brand: '',
					model: '',
					width: '',
					ratio: '',
					diameter: '',
					promotion: '',
				},
				page: this.activePage,
				sortDirection: Constants.SORT_STOCK_DIRECTION.DEFAULT,
				sortColumn: Constants.SORT_STOCK_COLUMN.DEFAULT
			}
		},
		submitSearch(param) {
			this.searchFilter.param = param;
			this.changePage(1);
		},
		async getStock() {
			let loader = this.$modalLoader.render();
	
            const result = await SellerService.getStock(this.searchFilter);

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			// Clear data to avoid trigger change in table
			this.resultList = [];

			// After result ready
			this.isResultReady = true;
			this.totalPage = result.data.totalPage;
			this.resultList = result.data.resultList;
			this.totalResult = result.data.totalResult;
			this.totalStock = result.data.totalStock;

            loader.hide();
		},
		changePage(page) {
			this.activePage = page;
			this.searchFilter.page = page;
			this.getStock();
		},
		sortStock(sort) {
			this.searchFilter.sortDirection = sort.direction;
			this.searchFilter.sortColumn = sort.column;

			// Reset Page and reload
			this.changePage(1);
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

h2 {
	font-size: 24px;
	font-family: 'Open Sans', 'Kanit';
	font-weight: bold;
	margin-bottom: 10px;
	color: #333;

	@media only screen and (max-width: $screenSmall) {
		font-size: 18px;
	}
}

.table-action {
	display: flex;
	align-items: center;
	justify-content: space-between;

	h3 {
		font-size: 22px;
		font-family: 'Open Sans', 'Kanit';
		font-weight: bold;
		color: #333;
		margin: 0;
	}

	.operation {
		.btn-action {
			padding: 5px 15px;
		}
	}
}

.remark {
	font-size: 13px;
	color: #555;
}
</style>